<template>
  <div>
    <!-- logo -->
    <a href="/" @click.prevent="goHome">
      <inline-svg :src="require('../assets/h4c-logo.svg')"></inline-svg>
    </a>
    <!-- juliet -->
    <!-- <a href="https://www.wearejuliet.com/" target="_blank">
      <inline-svg :src="require('../assets/juliet.svg')"></inline-svg>
    </a> -->
  </div>
</template>

<script>
import gsap from 'gsap'
export default {
  methods: {
    // Scroll to top when H4C logo is clicked
    goHome () {
      if (location.pathname === '/') {
        gsap.to(window, { scrollTo: 0 })
      } else {
        window.location = '/'
      }
    }
  }
}
</script>
