<template>
  <header>
    <headerLeftLinks2></headerLeftLinks2>
  </header>
</template>

<script>
import headerLeftLinks2 from '../components/header-left-links-2.vue'

export default {
  components: {
    headerLeftLinks2
  }
}
</script>
