<template>
  <header>
    <headerRightLinks1></headerRightLinks1>
  </header>
</template>

<script>
import headerRightLinks1 from '../components/header-right-links-1.vue'

export default {
  components: {
    headerRightLinks1
  }
}
</script>
